import React, {useEffect, useState} from "react";
import {Badge, Button, message, Popconfirm, PopconfirmProps, Radio, Table} from "antd";
import {
    reqDeleteFaceClassic,
    reqGetFaceClassicList,
    reqgetFaceClassicTypeList,
    reqUpdateFaceClassic
} from "@/api/f/faceClassic";
import styles from './index.module.css';
import fFaceClassicStyles from '../../../../f/faceClassic/index.module.css'

const FaceClassicList = () => {
    const [faceClassicList, setFaceClassicList] = useState([]);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(200); // 更改 pageSize 与 pagination 一致
    const userId = JSON.parse(localStorage.getItem('userInfo') as string).userId;
    const [currentId,setCurrentId] = useState('')
    const [updateIsVip,setUpdateIsVip] = useState('')
    const openDeleteTip = (item:any) => {
        setCurrentId(item.id)
    }
    const opeUpdateTip = (item:any) => {
        console.log("此时的值是",item.isVip)
        setUpdateIsVip(item.isVip)
        setCurrentId(item.id)
    }
    const confirm: PopconfirmProps['onConfirm'] = (e) => {
        reqDeleteFaceClassic({id:currentId}).then(res=>{
            if(res.status){
                message.success("删除成功~")
                reqgetFaceClassicTypeList({userId, b_orf: 'b'}).then(res => {
                    if (res.status) {
                        setTypeList(res.data)

                    }
                })
                let ploay = {
                    type,
                    current,
                    pageSize,
                    isCheck: '0',
                    userId,
                    b_orf: 'b'
                };
                fetchData(ploay);
            }else{
                message.error("删除失败~")
            }
        })
    };
    const confirmUpdate: PopconfirmProps['onConfirm'] = (e) => {
        reqUpdateFaceClassic({id:currentId,isVip:updateIsVip}).then(res=>{
            if(res.status){
                message.success("修改成功~")
                let ploay = {
                    type,
                    current,
                    pageSize,
                    isCheck: '0',
                    userId,
                    b_orf: 'b'
                };
                fetchData(ploay);
            }else{
                message.error("修改失败~")
            }
        })
    };
    const updateBox=()=>{
        return (
            <div>
                <Radio.Group onChange={onUpdateShowChange} value={updateIsVip}>
                    <Radio value='0'>李&赵</Radio>
                    <Radio value='1'>李</Radio>
                    <Radio value='2'>赵</Radio>
                </Radio.Group>
            </div>
        )
    }
    const columns: any = [
        {
            title: "",
            dataIndex: "index",
            render: (_: any, __: any, index: number) => (current - 1) * pageSize + index + 1,
            width: 50,
            align: 'center',
        },
        {
            title: '问题名称',
            dataIndex: 'title',
            key: 'title',
            width: 50,
            align: 'center',
        },     {
            title: '显示方式',
            dataIndex: 'userId',
            key: 'userId',
            render: (_: any, __: any) => {
                return __.isVip === '0' ? (<div>全部</div>) : (
                    <div>
                        {
                            __.isVip === '1'?'李':'赵'
                        }
                    </div>
                );
            },
            width: 50,
            align: 'center',
        },
            // {
            //     title: '作者',
            //     dataIndex: 'userId',
            //     key: 'userId',
            //     render: (_: any, __: any) => {
            //         return __.userId === '4500fbd3-5e3c-407b-b158-51e1ed19ee3a' ? '李' : '赵';
            //     },
            //     width: 50,
            //     align: 'center',
            // },
        {
            title: '添加时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 50,
            align: 'center',
        },
        {
            title: '频率',
            dataIndex: 'rate',
            key: 'rate',
            width: 50,
            align: 'center',
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            render: (_: any, __: any) => {
                return (
                    <div>
                        <Popconfirm
                            title="警告"
                            description={updateBox}
                            onConfirm={confirmUpdate}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button  type="primary"  onClick={()=>opeUpdateTip(__)}    >修改</Button>
                        </Popconfirm>

                        <Popconfirm
                            title="警告"
                            description="你确定要删除该问题吗？"
                            onConfirm={confirm}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button type="primary" danger onClick={() => openDeleteTip(__)}>删除</Button>
                        </Popconfirm>

                    </div>
                )
            },
            width: 50,
            align: 'center',
        },
    ];

    const fetchData = (ploay: any) => {

        reqGetFaceClassicList(ploay).then(res => {
            if (res.status) {
                setFaceClassicList(res.data);
            } else {
                message.error("获取面经列表失败~");
            }
        });
    };
    const [typeList, setTypeList] = useState([])
    const [type, setType] = useState('1')
    useEffect(() => {
        reqgetFaceClassicTypeList({userId, b_orf: 'b'}).then(res => {
            if (res.status) {
                setTypeList(res.data)

            }
        })
        let ploay = {
            type,
            current,
            pageSize,
            isCheck: '0',
            userId,
            b_orf: 'b'
        };
        fetchData(ploay);
    }, [current, pageSize]); // 将 current 和 pageSize 加入依赖数组
    const onChange = (e: any) => {
        setFaceClassicList([])
        setType(() => {
            let ploay = {
                type: e.target.value,
                current,
                pageSize,
                isCheck: '0',
                userId,
                b_orf: 'b'
            }
            fetchData(ploay)
            return e.target.value
        })

    };
    const onUserChange = (checkedValues: any) => {
        console.log('checked = ', checkedValues);
        setIsVip(checkedValues.target.value)
    };
    const onUpdateShowChange = (checkedValues: any) => {
        console.log('checked = ', checkedValues);
        setUpdateIsVip(checkedValues.target.value)
    };
    const [isVip, setIsVip] = useState('0');
    return (
        <div className={styles['mainBox']}>
            <div className={styles['headerTop']}>
                <Radio.Group className={fFaceClassicStyles['myRadio']} style={{marginRight: 10}} buttonStyle="solid"
                             value={type} onChange={onChange}
                             defaultValue="1">
                    {
                        typeList.length > 0 && typeList.map((item: any) => {

                            return <Badge color="green" count={item.num}>
                                <Radio.Button value={item.id}>{item.value}</Radio.Button>
                            </Badge>
                        })
                    }
                </Radio.Group>
                {/*0 李&赵*/}
                {/*1 李*/}
                {/*2 赵*/}
                <Radio.Group onChange={onUserChange} value={isVip}>
                    <Radio value='0'>李&赵</Radio>
                    <Radio value='1'>李</Radio>
                    <Radio value='2'>赵</Radio>
                </Radio.Group>
            </div>
            <Table
                scroll={{y: 'calc(100vh - 210px)'}}
                bordered
                style={{border: '1px solid red'}}
                size="small"
                dataSource={faceClassicList}
                columns={columns}
                pagination={false}
            />
        </div>
    );
};

export default FaceClassicList;
