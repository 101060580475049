import React, { useEffect, useRef } from "react";
import Prism from "prismjs";
import styles from "@/pages/f/faceClassic/index.module.css";
import BlogItemStyles from "../../articleMangement/blogItem/index.module.css";
import "prismjs/themes/prism-okaidia.css";
import "prismjs/components/";
import "prismjs/components/prism-java";
import "prismjs/components/prism-bash.min.js";
import "prismjs/components/prism-typescript";
import "prismjs/plugins/line-numbers/prism-line-numbers.min.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.min.js";
import "prismjs/plugins/toolbar/prism-toolbar.min.css";
import "prismjs/plugins/toolbar/prism-toolbar.min.js";
import "prismjs/plugins/show-language/prism-show-language.min.js";
import { Modal } from "antd";

interface AnswerItemProps {
    data: {
        answer: string;
    };
}

const AnswerItem: React.FC<AnswerItemProps> = (props) => {
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // 代码高亮
        Prism.highlightAll();

        // 图片预览事件绑定
        const content = contentRef.current;

        if (content) {
            const imgElements: NodeListOf<HTMLImageElement> = content.querySelectorAll("img");

            const handleImgClick = (event: MouseEvent) => {
                const img = event.target as HTMLImageElement;
                Modal.info({
                    title: null, // 去掉顶部
                    footer: null, // 去掉底部
                    maskClosable: true, // 禁止点击阴影关闭
                    closable: false, // 禁用右上角的关闭按钮
                    content: (
                        <div style={{
                            height: "85vh",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <img
                                src={img.src}
                                alt="预览"
                                style={{
                                    maxHeight: "100%", // 限制高度不超过容器高度
                                    maxWidth: "100%",  // 限制宽度不超过容器宽度
                                    objectFit: "contain", // 保持图片比例
                                }}
                            />
                        </div>
                    ),
                    icon: null, // 去掉默认图标
                    width: "65vw",
                    style: {top: 35}, // 距离顶部 50px
                });
            };

            // 绑定事件
            imgElements.forEach((img) => {
                img.style.cursor = "pointer";
                img.addEventListener("click", handleImgClick);
            });

            // 清理绑定的事件
            return () => {
                imgElements.forEach((img) => {
                    img.removeEventListener("click", handleImgClick);
                });
            };
        }
    }, [props.data.answer]); // 依赖于 `answer`，每次更新时重新绑定

    return (
        <div className={`${BlogItemStyles["myStyleOnly"]} ${styles["myWidth"]}`}>
            <div
                className={`${BlogItemStyles["my-line-numbers"]} line-numbers pre-mac`}
                ref={contentRef}
                dangerouslySetInnerHTML={{ __html: props.data.answer }}
            />
        </div>
    );
};

export default AnswerItem;
