import styles from './index.module.css'
import {Button, Checkbox, GetProp} from "antd";
import {getDays} from "@/utils/getTime";

const TodoList = () => {
    const options = [
        { label: 'Apple', value: 'Apple' },
        { label: 'Pear', value: 'Pear' },
        { label: 'Orange', value: 'Orange' },
    ];
    const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
        console.log('checked = ', checkedValues);
    };
    return (
        <div className={styles['mainBox']}>

            <div className={styles['contentBox']}>


                <div className={styles['contentL']}>
                <div className={styles['completed']}>
                    <div className={styles['title']}>待完成</div>
                    <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                        {
                            options.map((item:any, index:any) => {
                                return (   <Checkbox value={item.value}>{item.label}</Checkbox>)
                            })
                        }
                    </Checkbox.Group>

                </div>
                    <div className={styles['incomplete']}>
                        <div className={styles['title']}>已完成</div>
                        <ul className={styles['incompleteUl']}>
                            {
                                options.map(item => {
                                    return (<li>{item.value} <span >恢复</span></li>)
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className={styles['contentR']}>
                    倒计时：{getDays("2025-01-28")}天
                </div>
            </div>
        </div>
    )
}
export default TodoList