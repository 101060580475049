import { useState, useEffect } from 'react';
import styles from './index.module.css';
import { ReactComponent as NotFoundIcon } from "./img/404.svg";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const nav = useNavigate();

    // 动态设置倒计时时间，假设从 5 秒开始
    const [timeLeft, setTimeLeft] = useState(5);

    const goHomePage = () => {
        nav('/fMainPage/fHomePage');
    };

    useEffect(() => {
        if (timeLeft === 0) {
            goHomePage();
        } else {
            // 设置定时器，每秒更新倒计时
            const timer = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);

            // 清除定时器
            return () => clearInterval(timer);
        }
    }, [timeLeft, nav]);

    return (
        <div className={styles['mainBox']}>
            <NotFoundIcon className={styles['iconStyle']} />
            <div className={styles['tip']}>喔呦！当前页面跑丢了</div>
            <div className={styles['backBtn']} onClick={goHomePage}>
                返回首页({timeLeft > 0 ? timeLeft : 0}秒后自动跳转)
            </div>
        </div>
    );
};

export default NotFound;
