import RichText from "@/pages/f/commonCompnents/RichText";
import {useEffect, useRef, useState} from "react";
import {Button, Checkbox, Form, FormProps, Input, message, Select} from "antd";
import {reqAddFaceClassic, reqgetFaceClassicTypeList} from "@/api/f/faceClassic";
import TextArea from "antd/es/input/TextArea";
import {adminUserId} from "@/utils/common";

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    console.log('Success:', values);
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const FaceClassicMangement = () => {
    const [content, setContent] = useState('');
    const richTextRef: any = useRef<any>(null);
    const userId =JSON.parse(localStorage.getItem("userInfo")as string).userId;

    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [saveForm, setSaveForm] = useState({
        title: '',
        type: '1',
        rate: '1',
        isCheck: '0',
        answer: '',
        isVip:userId===adminUserId?'1':'2',
        userId
    });

    const getEditorContent = () => {
        if (richTextRef.current) {
            const editorContent = richTextRef.current.getEditorContent();
            setContent(editorContent);
            setSaveForm((prevState) => ({
                ...prevState,
                answer: editorContent,
            }));
        }
    };
    useEffect(() => {
        reqgetFaceClassicTypeList({userId, b_orf: 'f'}).then(res => {
            if (res.status) {
                setTypeList(res.data)
                let resSelect = []
                for (let i = 0; i < res.data.length; i++) {
                    let item = {
                        value: res.data[i].id,
                        label: res.data[i].value,

                    }
                    resSelect.push(item)

                }
                setSelectTypeList(resSelect)
            }
        })

    }, []);
    useEffect(() => {
        if (content) {
            reqAddFaceClassic(saveForm).then(res => {
                if (res.status) {
                    if (richTextRef){
                        richTextRef.current.initRichText();
                    }
                    message.success("添加成功~");
                } else {
                    message.error("添加失败~");
                }
            });
        }
    }, [saveForm.answer]);
    const saveFaceClassic = () => {
        getEditorContent();


    };

    const titleChange = (e: any) => {
        setSaveForm((prevState) => ({
            ...prevState,
            title: e.target.value,
        }));
    };

    const handleRateChange = (value: string) => {
        setSaveForm((prevState) => ({
            ...prevState,
            rate: value,
        }));
    };

    const handleTypeChange = (value: string) => {
        setSaveForm((prevState) => ({
            ...prevState,
            type: value,
        }));
    };

    const onChange = (e:any) => {
        console.log('checked = ', e.target.checked);
        setChecked(e.target.checked);
    };
    const label = `${checked ? '精华' : '普通'}`;
    const [typeList, setTypeList] = useState([])
    const [selectTypeList, setSelectTypeList]: any = useState([])
    return (
        <div>
            <Form
                layout="inline"
                name="basic"
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="问题名称"
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <TextArea
                        style={{width:400}}
                        value={saveForm.title} onChange={titleChange}
                        placeholder="请输入问题"
                        autoSize={{ minRows: 3, maxRows: 3 }}
                    />
                    {/*<Input value={saveForm.title} onChange={titleChange} />*/}
                </Form.Item>

                <Form.Item<FieldType>
                    label="出现频率"
                    rules={[{ required: true, message: 'Please select the frequency!' }]}
                >
                    <Select
                        value={saveForm.rate}
                        style={{ width: 120 }}
                        onChange={handleRateChange}
                        options={[
                            { value: '1', label: '低' },
                            { value: '2', label: '中' },
                            { value: '3', label: '高' },
                        ]}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    label="类型"
                    rules={[{ required: true, message: 'Please select the type!' }]}
                >
                    <Select
                        value={saveForm.type}
                        style={{ width: 120 }}
                        onChange={handleTypeChange}
                        options={selectTypeList}
                    />
                </Form.Item>
                {/*<Form.Item<FieldType>*/}
                {/*    label="传输方式"*/}
                {/*    rules={[{ required: true, message: 'Please select the frequency!' }]}*/}
                {/*>*/}
                {/*    <Checkbox checked={checked} disabled={disabled} onChange={onChange}>*/}
                {/*        {label}*/}
                {/*    </Checkbox>*/}
                {/*</Form.Item>*/}

            </Form>
            <RichText data="请填写内容~" ref={richTextRef} />
            {/*<button onClick={getEditorContent}>我是父组件的button</button>*/}
            {/*content是: {content.toString()}*/}
            <Button onClick={saveFaceClassic}>添加</Button>
        </div>
    );
};

export default FaceClassicMangement;